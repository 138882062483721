import React from "react";
import { useParams } from "react-router-dom";
import MessageInput from "./MessageInput";
import { MessageList } from "./MessageList";

export default function CreateAccount() {
  let { chatID } = useParams();



  return (
    <>
      <main>
        <div className="max-w-7xl mx-auto h-screen relative">
          {/* Replace with your content */}
          <div className="">
            <div className="p-4 rounded-t-lg border-2 border-b-0 shadow-lg border-gray-200 ">
              <MessageList roomID={chatID} />
            </div>
            
            <div className="hidden sm:block bottom-0 sticky w-full " >
            <MessageInput roomID={chatID} />
            </div>
            <div className="sm:hidden bottom-0 fixed w-full p-5 bg-white" >
            <MessageInput roomID={chatID} />
            </div>


          </div>
          {/* /End replace */}
        </div>
      </main>
    </>
  );
}
