import { createContext, useContext, useEffect, useState } from "react";
import { db, auth, storage } from "../firebase/firebase";
import {
  setDoc,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
  query,
  collection,
  where,
  increment,
  addDoc,
  orderBy,
  getDocs,
  limit,
  GeoPoint,
  serverTimestamp,
  arrayRemove,
  startAfter,
  deleteDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  sendEmailVerification,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  linkWithCredential,
  signInWithPopup,
} from "firebase/auth";

const UserContext = createContext();

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profile, setProfile] = useState({});
  const [chats, setChats] = useState([]);

  const [userStatuses, setUserStatus] = useState([]);
  const [posts, setPosts] = useState([]);
  const [likedPosts, setLikedPosts] = useState({});
  const [files, setFiles] = useState([]);
  const [memories, setMemories] = useState([]);
  const [writtenmemories, setWrittenMemories] = useState([]);
  const [postLimit, setPostLimit] = useState(10);
  const [messageLimit, setMessageLimit] = useState(10);
  const [allUsers, setAllUsers] = useState([]);


  const navigate = useNavigate();

  // User Auth system

  const getUserByEmail = async (email) => {
    const querySnapshot = await getDocs(collection(db, "usersEmails"));
    const users = querySnapshot.docs.map((doc) => doc.data());
    const userExists = users.find((user) => user.email === email);
    return userExists;
  };

  const createUser = async (email, password, userData) => {

    try {
      const userExists = await getUserByEmail(email);
      if (userExists) {
        alert("A user with this email already exists. Please login instead.");
        navigate("/login");
        return;
      } else {
        return createUserWithEmailAndPassword(
          auth,
          email,
          password,
        ).then((userCredential) => {
          setDoc(doc(db, "usersEmails", userCredential.user.uid), {
            email: email,
          });
          setDoc(doc(db, "users", userCredential.user.uid), {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: email,
            id: userCredential.user.uid,
            lastActive: serverTimestamp(),
            active: true,
            notifications: [],
            postCount: 0,
            profileFinished: false,
          });
          setProfile(userData);
          navigate('/welcome')
        });
      }

    } catch (error) {
      alert(`Failed to create user: ${error.message}`);
    }


  };

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
      updateDoc(doc(db, "users", userCredential.user.uid), {
        lastActive: serverTimestamp()
      })
      navigate("/news-feed");
    });

  };

  const logout = () => {
    setIsLoggedIn(false);
    signOut(auth);
    navigate("/");
    window.location.reload();
  };

  const finishProfile = (userData) => {
    try {
      updateDoc(doc(db, "users", user.uid), {
        profileFinished: true,
        lastActive: serverTimestamp(),
        firstName: userData.firstName,
        lastName: userData.lastName,
        contactEmail: userData.contactEmail,
        phone: userData.phone,
        about: userData.about,
        address: userData.address,
        city: userData.city,
        state: userData.state,
        zip: userData.zip,
        proffesion: userData.proffesion,
        hobby: userData.hobby,
        college: userData.college,
        mission: userData.mission,
        birthday: userData.birthday,
        country: userData.country,
      });

      setFiles([]);
    } catch (error) {
      alert(error.message);
    }
  };



  const uploadMemories = async (memoriesData) => {

    await addDoc(collection(db, "memories"), {
      people: memoriesData.people,
      location: memoriesData.location,
      photos: memoriesData.photos,
      year: memoriesData.year,
      story: memoriesData.story,
    });
  }

  const uploadWrittenMemories = async (memoriesData) => {

    await addDoc(collection(db, "writtenmemories"), {
      people: memoriesData.people,
      location: memoriesData.location,
      year: memoriesData.year,
      story: memoriesData.story,
      title: memoriesData.title,
    });
  }

  const getAllWrittenMemories = async () => {
    const querySnapshot = await getDocs(collection(db, "writtenmemories"));
    const memories = querySnapshot.docs.map((doc) => doc.data());
    setWrittenMemories(memories);
  }


  const getAllMemories = async () => {
    const querySnapshot = await getDocs(collection(db, "memories"));
    const memories = querySnapshot.docs.map((doc) => doc.data());
    setMemories(memories);
  }

  const queryMemoriesByPeople = async (search) => {
    const q = query(collection(db, "memories"), where("people", "==", search));
    const querySnapshot = await getDocs(q);
    const memories = querySnapshot.docs.map((doc) => doc.data());
    setMemories(memories);
  }

  const queryMemoriesByYear = async (search) => {
    const q = query(collection(db, "memories"), where("year", "==", search));
    const querySnapshot = await getDocs(q);
    const memories = querySnapshot.docs.map((doc) => doc.data());
    setMemories(memories);
  }

  const queryMemoriesByLocation = async (search) => {
    const q = query(collection(db, "memories"), where("location", "==", search));
    const querySnapshot = await getDocs(q);
    const memories = querySnapshot.docs.map((doc) => doc.data());
    setMemories(memories);
  }


  const uploadPost = async (postData) => {
    const docRef = await addDoc(collection(db, "posts"), {
      post: postData.post,
      mood: postData.mood,
      profileUrl: postData.profileUrl,
      firstName: postData.firstName,
      lastName: postData.lastName,
      timestamp: serverTimestamp(),
      user_id: postData.id,
      likes: 0,
      imageUrls: files // initialize imageUrls array
    });

    await updateDoc(doc(db, "posts", docRef.id), {
      docRef: docRef.id,
    });

    await updateDoc(doc(db, "users", postData.id), {
      postCount: postData.postCount + 1,
      lastActive: serverTimestamp(),
    });

    setFiles([]);
  };

  const newChatMessage = async (roomID, postData, text) => {
    try {
      await addDoc(collection(db, "chat-rooms", roomID, "messages"), {
        text: text.trim(),
        mood: postData.mood,
        profileUrl: postData.profilePic,
        firstName: postData.firstName,
        lastName: postData.lastName,
        timestamp: serverTimestamp(),
        user_id: postData.user_id,
        imageUrls: files // initialize imageUrls array
      });
    } catch (err) {
      alert(err.message)
    }
    setFiles([]);
  };

  const getAllMessages = (roomID, callback) => {
    const q = query(
      collection(db, "chat-rooms", roomID, "messages"),
      orderBy("timestamp", "asc"),
    );
    return onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(messages);
    });

  };


  const setProfilePic = async () => {

    try {

      await updateDoc(doc(db, "users", user.uid), {
        profilePic: files[0].cdnUrl,
        lastActive: serverTimestamp(),
      });

      setFiles([]);
    } catch (err) {
      alert(err.message)
    }

    navigate("/news-feed");
  }




  const updateStatus = async (status) => {
    await updateDoc(doc(db, "users", status.id), {
      status: status.text,
      lastActive: serverTimestamp()
    });
    try {
      await updateDoc(doc(db, "statai", "allStatai"), {
        [status.id]: { id: status.id, status: status.text, profileUrl: status?.profileUrl, firstName: status.firstName, lastName: status.lastName, time: serverTimestamp() }
      });
    } catch (err) {
      alert(err.message)
    }

  }

  const commentOnPost = async (postID, comment, userData) => {
    await updateDoc(doc(db, "posts", postID), {
      comments: arrayUnion({
        comment: comment,
        user_id: userData.id,
        firstName: userData.firstName,
        lastName: userData.lastName,
        profileUrl: userData.profilePic,
        timestamp: new Date(),
      })
    })
  };

  const postLiked = async (postID, currentLikes, userData) => {
    await updateDoc(doc(db, "posts", postID), {
      likes: currentLikes + 1,
      peopleWhoLiked: arrayUnion({
        id: userData.id,
        name: userData.firstName + ' ' + userData.lastName,
        picture: userData.profilePic,
      })
    });
    const newDocRef = await addDoc(collection(db, "likedPostRef"), {
      postId: postID,
      userId: userData.id
    });
    await updateDoc(doc(db, "users", userData.id), {
      likedPosts: arrayUnion(newDocRef)
    });
  }

  const removeLikedPostRef = async (postID, userID) => {
    const querySnapshot = await getDocs(query(collection(db, "likedPostRef"), where("postId", "==", postID), where("userId", "==", userID)));
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  };

  const removeLikedPostFromUser = async (postID, userID) => {
    await updateDoc(doc(db, "users", userID), {
      likedPosts: arrayRemove(postID),
    });
  };


  const postUnliked = async (postID, currentLikes, userData) => {
    await updateDoc(doc(db, "posts", postID), {
      likes: currentLikes - 1,
      peopleWhoLiked: arrayRemove({
        id: userData.id,
        name: userData.firstName + " " + userData.lastName,
        picture: userData.profilePic,
      }),
    });
    await removeLikedPostRef(postID, userData.id);
    await removeLikedPostFromUser(postID, userData.id);
  };


  const sendMessage = async (roomID, userData, text) => {
    try {
      await addDoc(collection(db, "chat-rooms", roomID, "messages"), {
        uid: userData.id,
        fistName: userData.firstName,
        lastName: userData.lastName,
        profilePic: userData.profilePic,
        text: text.trim(),
        timestamp: serverTimestamp(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAllUsers = () => {
    const q = query(collection(db, "users"), orderBy("firstName", "asc"));
    return onSnapshot(q, (querySnapshot) => {
      const users = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAllUsers(users);
      window.localStorage.setItem("allUsers", JSON.stringify(users));
    });
  };


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setIsLoggedIn(true);
        onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
          setProfile(doc.data());
        });
        onSnapshot(doc(db, "statai", "allStatai"), (doc) => {
          setUserStatus(doc.data());

        });
        onSnapshot(query(collection(db, 'posts'), orderBy('timestamp', 'desc'), limit(postLimit)), (querySnapshot) => {
          const posts = [];
          const likedPosts = {};
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            data.docRef = doc.id;
            posts.push(data);

            // Check if current user has liked this post
            if (data.peopleWhoLiked && data.peopleWhoLiked.some((person) => person.id === currentUser.uid)) {
              likedPosts[data.docRef] = true;
            } else {
              likedPosts[data.docRef] = false;
            }
          });
          setPosts(posts);
          setLikedPosts(likedPosts);
        });
        getAllUsers()
        console.log("It ran again");
      } else {
        setIsLoggedIn(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [postLimit]);


  return <UserContext.Provider value={{
    createUser,
    profile,
    signIn,
    logout,
    userStatuses,
    updateStatus,
    uploadPost,
    posts,
    sendMessage,
    postLiked,
    postUnliked,
    setLikedPosts,
    likedPosts,
    uploadMemories,
    files,
    setFiles,
    postLimit,
    setPostLimit,
    setProfilePic,
    newChatMessage,
    getAllMessages,
    chats,
    setMessageLimit,
    messageLimit,
    allUsers,
    finishProfile,
    isLoggedIn,
    memories,
    setMemories,
    getAllMemories,
    uploadWrittenMemories,
    writtenmemories,
    setWrittenMemories,
    getAllWrittenMemories,
    commentOnPost
  }}>{children}</UserContext.Provider>;
}

export const UserAuth = () => {
  return useContext(UserContext);
};
