import { useCallback, useRef, useEffect, useState } from "react";
import * as LR from "@uploadcare/blocks";
import st from "./App.module.css";
import { PACKAGE_VERSION } from "@uploadcare/blocks/env";
import { UserAuth } from "../context/AuthContext";
LR.registerBlocks(LR);

function UploadPhotos() {
    const { setFiles } = UserAuth()
    let dataOutputRef = useRef();
    const handleUploaderEvent = useCallback((e) => {
        const { data } = e.detail;
        setFiles(data);
    }, []);

    useEffect(() => {
        let el = dataOutputRef.current;
        el.addEventListener("lr-data-output", handleUploaderEvent);
        return () => {
            el.removeEventListener("lr-data-output", handleUploaderEvent);
        };
    }, [handleUploaderEvent]);

    return (
        <div>


            <div className=""  >
           
                <lr-file-uploader-regular
                    class={st.uploaderCfg}
                    css-src={`https://unpkg.com/@uploadcare/blocks@${PACKAGE_VERSION}/web/file-uploader-regular.min.css`}
                >
                    <lr-data-output
                        ref={dataOutputRef}
                        use-event
                        hidden
                        class={st.uploaderCfg}
                        onEvent={handleUploaderEvent}
                    ></lr-data-output>
                </lr-file-uploader-regular>
            </div>
        </div>
    );
}

export default UploadPhotos;
