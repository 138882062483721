import { Fragment, useState } from 'react'
import { ChevronLeftIcon, EnvelopeIcon, FunnelIcon, MagnifyingGlassIcon, PencilIcon, PhoneIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import profilePic from '../assets/profilepic.png'





function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Profile() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const { id } = useParams();
    const { allUsers, profile } = UserAuth();

    const navigate = useNavigate()

    // const user = allUsers.find((user) => user.id === id)
    const user = allUsers?.find((user) => user.id === id)

    const handleUpload = () => {
        navigate('/upload')
      }
    


    return (
        <>

            <div class="h-screen bg-white">
                <div class="h-full overflow-hidden">

                    <div className="flex h-full">


                        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">

                            <div className="relative z-0 flex flex-1 overflow-hidden">
                                <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                                    {/* Breadcrumb */}
                                    <nav className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden" aria-label="Breadcrumb">
                                        <button className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900">
                                            <ChevronLeftIcon className="-ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            <span>Directory</span>
                                        </button>
                                    </nav>

                                    <article>
                                        {/* Profile header */}
                                        <div>
                                            <div>
                                                <img className="h-32 w-full object-cover lg:h-48" src="https://images.unsplash.com/photo-1444628838545-ac4016a5418a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80" alt="" />
                                            </div>
                                            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                                                <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                                                    <button className="flex" onClick={handleUpload}>
                                                        <img
                                                            className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32 object-cover object-center"
                                                            src={user?.profilePic || profilePic}
                                                            alt=""
                                                        />
                                                    </button>
                                                    <div className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                                                        <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                                            <h1 className="truncate text-2xl font-bold text-gray-900">{user?.firstName} {user?.lastName}</h1>
                                                        </div>
                                                        <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-x-4 sm:space-y-0">
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            >
                                                                <EnvelopeIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                Message
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="inline-flex justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                            >
                                                                <PhoneIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                Call
                                                            </button>
                                                            {id === profile?.id && (
                                                                <Link
                                                                to="/edit-profile"
                                                                className="inline-flex justify-center gap-x-1.5 rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-emerald-800"
                                                            >
                                                                <PencilIcon className="-ml-0.5 h-5 w-5 text-white" aria-hidden="true" />
                                                                Edit Profile
                                                            </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                                                    <h1 className="truncate text-2xl font-bold text-gray-900">{user?.name}</h1>
                                                </div> */}
                                            </div>
                                        </div>

                                        {/* Tabs */}
                                        <div className="mt-6 sm:mt-2 2xl:mt-5">
                                            <div className="border-b border-gray-200">
                                                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">

                                                </div>
                                            </div>
                                        </div>

                                        {/* Description list */}
                                        <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.phone}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.email}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Mission</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.mission}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">City</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.city}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">State</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.state}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Spouse</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.phone}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Birthday</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.birthday}</dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">College</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{user?.college}</dd>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">About</dt>
                                                    <dd
                                                        className="mt-1 max-w-prose space-y-5 text-sm text-gray-900"
                                                        dangerouslySetInnerHTML={{ __html: user?.about }}
                                                    />
                                                </div>
                                            </dl>
                                        </div>

                                        {/* Team member list */}
                                        <div className="mx-auto mt-8 max-w-5xl px-4 pb-12 sm:px-6 lg:px-8">
                                            <h2 className="text-sm font-medium text-gray-500">Children</h2>
                                            <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                                {user?.children?.map((person) => (
                                                    <div
                                                        key={person.handle}
                                                        className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-pink-500 focus-within:ring-offset-2 hover:border-gray-400"
                                                    >
                                                        <div className="flex-shrink-0">
                                                            <img className="h-10 w-10 rounded-full object-cover object-center" src={person.imageUrl} alt="" />
                                                        </div>
                                                        <div className="min-w-0 flex-1">
                                                            <a href="#" className="focus:outline-none">
                                                                <span className="absolute inset-0" aria-hidden="true" />
                                                                <p className="text-sm font-medium text-gray-900">{person.name}</p>
                                                                <p className="truncate text-sm text-gray-500">{person.role}</p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </article>
                                </main>
                                <aside className="hidden w-96 flex-shrink-0 border-r border-gray-200 xl:order-first xl:flex xl:flex-col">
                                    <div className="px-6 pb-4 pt-6">
                                        <h2 className="text-lg font-medium text-gray-900">Directory</h2>
                                        <p className="mt-1 text-sm text-gray-600">Search directory of {allUsers?.length} family members</p>
                                        <form className="mt-6 flex gap-x-4" action="#">
                                            <div className="min-w-0 flex-1">
                                                <label htmlFor="search" className="sr-only">
                                                    Search
                                                </label>
                                                <div className="relative rounded-md shadow-sm">
                                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    </div>
                                                    <input
                                                        type="search"
                                                        name="search"
                                                        id="search"
                                                        className="block w-full rounded-md border-0 py-1.5 pl-10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                                                        placeholder="Search"
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* Directory list */}
                                    <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
                                      
                                                <ul role="list" className="relative z-0 divide-y divide-gray-200">
                                                    {allUsers?.map((person) => (
                                                        <li key={person.id}>
                                                            <div className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 hover:bg-gray-50">
                                                                <div className="flex-shrink-0">
                                                                    <img className="h-10 w-10 rounded-full object-cover object-center" src={person?.profilePic || profilePic} alt="" />
                                                                </div>
                                                                <div className="min-w-0 flex-1">
                                                                    <Link to={`/profile/${person.id}`} className="focus:outline-none">
                                                                        {/* Extend touch target to entire panel */}
                                                                        <span className="absolute inset-0" aria-hidden="true" />
                                                                        <p className="text-sm font-medium text-gray-900">{person.firstName} {person.lastName}</p>
                                                                        <p className="truncate text-sm text-gray-500">{person?.city} {person?.city}</p>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                           
                                      
                                    </nav>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
