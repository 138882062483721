import { Fragment, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo.svg";
import pic from "../assets/profilepic.png"
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import NavBarLink from "./NavBarLink";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const { profile, signIn, logout } = UserAuth();  
  

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const navigate = useNavigate()

  const handleLogin = (e) => {
    e.preventDefault();
    signIn(loginEmail, loginPassword);
    navigate('/news-feed');
  }

  const handleLogOut = (e) => {
    e.preventDefault();
    logout();
    navigate('/')

  }


  return (
    <Disclosure as="nav" className="bg-emerald-800 sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    className="block h-6 w-auto lg:hidden"
                    src={logo}
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-6 w-auto lg:block"
                    src={logo}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    {profile?.active && (
                      <>
                      <NavBarLink to="/news-feed">Home</NavBarLink>
                      <NavBarLink to={`/profile/${profile?.id}`}>Directory</NavBarLink>
                      <NavBarLink to="/chat/Everyone">Family Chat</NavBarLink>
                      <NavBarLink to="/memories">Memories</NavBarLink>
                      <NavBarLink to="/calendar">Calendar</NavBarLink>
                      </>
                    )}
                    
                  </div>
                </div>
              </div>
              {profile?.active ? (
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex items-center">
                    <button
                      type="button"
                      className="rounded-full bg-emerald-800 p-1 text-emerald-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-800"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-8 w-8" aria-hidden="true" />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-emerald-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-800">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full object-center object-cover"
                            src={profile?.profilePic || pic}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/profile/${profile?.id}`}
                                className={classNames(
                                  active ? "bg-emerald-100" : "",
                                  "block px-4 py-2 text-sm text-emerald-700"
                                )}
                              >
                                Your Profile
                              </Link>
                            )}
                          </Menu.Item>
                          {/* <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active ? "bg-emerald-100" : "",
                                  "block px-4 py-2 text-sm text-emerald-700"
                                )}
                              >
                                Settings
                              </a>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                className={classNames(
                                  active ? "bg-emerald-100" : "",
                                  "block px-4 py-2 text-sm text-emerald-700 w-full text-left"
                                                                   

                                )}
                                onClick={(e) => {handleLogOut(e)}}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : (
                <div className="hidden sm:ml-6 xl:block">
                  <form onSubmit={handleLogin}>
                  <div className="flex items-center">
                  <input
                        type="text"
                        className="px-4 py-2 mr-2 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-emerald-800"
                        placeholder="Email"
                        onChange={e => setLoginEmail(e.target.value)}

                      />
                      <input
                        type="password"
                        className="px-4 py-2 mr-2 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-emerald-800"
                        placeholder="Password"
                        onChange={e => setLoginPassword(e.target.value)}
                      />
                    <button
                      type="button"
                      className="rounded-md px-4 py-2 bg-emerald-200 hover:bg-emerald-700 p-1 text-emerald-900 hover:text-white ring-2 ring-emerald-900  focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-800"
                      onClick={(e) => {handleLogin(e)}}
                    >
                      Login
                      
                    </button>                  
                  </div>
                  </form>
                </div>
            
              )}

              <div className="-mr-2 flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-emerald-400 hover:bg-emerald-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {/* Current: "bg-emerald-900 text-white", Default: "text-emerald-300 hover:bg-emerald-700 hover:text-white" */}
              {profile?.active ? (
                <>
                <Disclosure.Button
                as={Link}
                to="/news-feed"
                className="block rounded-md bg-emerald-900 px-3 py-2 text-base font-medium text-white"
              >
                Home
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to={`/profile/${profile?.id}`}
                className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
              >
                Directory
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/chat/Everyone"
                className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
              >
                Family Chat
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/memories"
                className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
              >
                Memories
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/calendar"
                className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
              >
                Calendar
              </Disclosure.Button>
              </>
              ) : (
                <>
                <Disclosure.Button
                as={Link}
                to="/create-account"
                className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
              >
                Create Acount
              </Disclosure.Button>
              <Disclosure.Button
              as={Link}
              to="/"
              className="block rounded-md px-3 py-2 text-base font-medium text-emerald-300 hover:bg-emerald-700 hover:text-white"
            >
              Login
            </Disclosure.Button>
            </>
              )}
              
            </div>
            {profile?.active && (
              <div className="border-t border-emerald-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full object-center object-cover"
                      src={profile?.profilePic || pic}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-white">
                      {profile?.firstName} {profile?.lastName}
                    </div>
                    <div className="text-sm font-medium text-emerald-400">
                      {profile?.email}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-emerald-800 p-1 text-emerald-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-emerald-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  <Disclosure.Button
                    as={Link}
                    to={`/profile/${profile?.id}`}
                    className="block rounded-md px-3 py-2 text-base font-medium text-emerald-400 hover:bg-emerald-700 hover:text-white"
                  >
                    Your Profile
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="button"
                    onClick={handleLogOut}
                    className="block rounded-md px-3 py-2 text-base font-medium text-emerald-400 hover:bg-emerald-700 hover:text-white"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
