import { UserAuth } from "../context/AuthContext"
import profileFiller from '../assets/profilepic.png'
import { ChatBubbleLeftIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";


export default function FollowFeed() {
  const { userStatuses, profile } = UserAuth();

  const navigate = useNavigate()

  const getTimeElapsed = (timestamp) => {
    const now = Date.now();
    const diff = now - timestamp?.toMillis();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const statusesArray = Object.values(userStatuses);
  statusesArray.sort((a, b) => b.time?.toMillis() - a.time?.toMillis());

  const handleStartChat = (chatID, e) => {
    e.preventDefault()
    const idA = profile?.id.toString(); // convert profile.id to string
    const idB = chatID.toString(); // convert chatID to string
  
    const firstID = idA.localeCompare(idB) < 0 ? profile?.id : chatID;
  
    navigate(`/chat/${firstID}`);
  };


  return (
    <div>
      <div className="divide-y divide-gray-200">

        {statusesArray.map((status, index) => (
          <div key={index} className="py-4">
            <div className="flex space-x-3">
              <img className="h-10 w-10 rounded-full object-center object-cover" src={status?.profileUrl || profileFiller} alt="" />
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-medium flex"><Link to={`/profile/${status.id}`} className="hover:text-emerald-600">{status?.firstName} {status?.lastName}</Link></h3>
                  <p className="text-sm text-gray-500">
                    {getTimeElapsed(status?.time)}
                  </p>
                </div>
                <p className="text-sm text-gray-500 ">
                  {status?.status}
                </p>
                {/* <button className="flex mt-2 items-center text-sm bg-emerald-100 rounded-full px-2 py-.5 hover:bg-emerald-200" onClick={(e) => {handleStartChat(status?.id, e)}}><ChatBubbleLeftIcon className="h-4 mr-1 flex"  /> Chat</button> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
