import React from "react";
import FollowFeed from "../components/FollowFeed";
import Posts from "../components/Posts";
import PostStatus from "../components/PostStatus";
import ProfileCard from "../components/ProfileCard";
import { ChatBubbleLeftRightIcon } from '@heroicons/react/20/solid'
import ChatRoomList from "../components/ChatRoomList";
import { Link } from "react-router-dom";

export default function Feed() {
  return (
    <div className="max-w-7xl mx-auto xl:grid xl:grid-cols-12 xl:gap-6 p-4">

      <div className="flex-1 xl:col-span-3 hidden xl:block xl:row-span-3">

        <div className="sticky top-20">
          <ProfileCard />
        </div>

      </div>

      <div className="flex-1 rounded-md col-span-6">
        <div className="bg-gray-50 drop-shadow-lg rounded-md">
          <div className=" p-4">
            <PostStatus />

          </div>
        </div>
        <div className="mt-4">
          <Posts />
        </div>

      </div>

      <div className="flex-1 xl:col-span-3 hidden xl:block">

        <div className="sticky top-20">
          <FollowFeed />
          {/* <ChatRoomList /> */}

        </div>

      </div>
      <Link
        to="/chat/Everyone"
        className="fixed bottom-4 right-4 rounded-full bg-emerald-600 p-2 text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 "
      >
        <ChatBubbleLeftRightIcon className="h-7 w-7" aria-hidden="true" />
      </Link>

    </div>
  );
}
