import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";

function useMessages(roomID, limit) {
    const [messages, setMessages] = useState([])
    const { getAllMessages } = UserAuth();

  useEffect(() => {
    const unsubscribe = getAllMessages(roomID, setMessages);
    return unsubscribe;
  }, [roomID, limit]);


  return messages;  
}


export { useMessages };