import { Link, useLocation } from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  

export default function NavBarLink({ to, children }) {
  const location = useLocation();

  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={classNames(
        isActive ? "bg-emerald-900 text-white" : "bg-transparent text-emerald-300",
        "rounded-md px-3 py-2 text-sm font-medium hover:bg-emerald-700 hover:text-white",
        
          
        
      )}
    >
      {children}
    </Link>
  );
}
