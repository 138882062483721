import { useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import Login from "./Login";

export default function Home() {
  const { createUser } = UserAuth();

  const [error, setError] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const userData = {
    firstName: firstName,
    lastName: lastName,
    displayName: firstName + " " + lastName,
    email: email,
    password: password,
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError(true);
    } else {
      createUser(email, password, userData);
      navigate('/welcome')
    }
  };

  return (
    <div className="bg-gray-50 h-screen">
      <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-5xl text-center -mt-12 sm:-mt-5">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex text-left">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Passwords do not match
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>
                      Please re-enter your password and make sure you confirm with the same password
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="xl:hidden">
            <Login />
          </div>
          <div className="hidden xl:block">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get started with Johnson Family Sharing
            </h2>
            <p className="mx-auto mt-4 max-w-xl text-lg leading-8 text-gray-600">
              FamBook connects <span className="font-bold">THE JOHNSON FAMILY</span>{" "}
              across the world!
            </p>
            <form className="grid grid-cols-6 gap-y-4 gap-x-4 mt-4 max-w-2xl mx-auto bg-white rounded-md drop-shadow-lg sm:py-10 sm:px-12 p-4">
              <div className="text-4xl col-span-6 mb-4 font-bold text-emerald-700 tracking-tight">
                Sign Up Today
              </div>
              <div className="relative sm:col-span-3 col-span-6">
                <label
                  htmlFor="firstname"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-gray-900"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="firstname"
                  id="firstname"
                  className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-lg sm:leading-6"
                  placeholder="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="relative sm:col-span-3 col-span-6">
                <label
                  htmlFor="lastname"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-gray-900"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-lg sm:leading-6"
                  placeholder="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="relative col-span-6 mt-2">
                <label
                  htmlFor="lastname"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-gray-900"
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-lg sm:leading-6"
                  placeholder="youremail@address.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="relative col-span-6 mt-2">
                <label
                  htmlFor="lastname"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-lg sm:leading-6"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="relative col-span-6 mt-2">
                <label
                  htmlFor="lastname"
                  className="absolute -top-2 left-2 inline-block bg-white px-1 text-sm font-medium text-gray-900"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  name="passwordconfirm"
                  id="passwordconfirm"
                  className="block w-full h-14 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-lg sm:leading-6"
                  placeholder="Confirm your password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="px-4 py-6 sm:px-10 col-span-6">
                <p className="text-sm leading-5 text-gray-500">
                  By signing up, you agree to our{" "}
                  <a
                    href="#"
                    className="font-medium text-gray-900 hover:underline"
                  >
                    Terms
                  </a>
                  ,{" "}
                  <a
                    href="#"
                    className="font-medium text-gray-900 hover:underline"
                  >
                    Data Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="#"
                    className="font-medium text-gray-900 hover:underline"
                  >
                    Cookies Policy
                  </a>
                  .
                </p>
              </div>
              <button
                className="col-span-6 rounded-md bg-emerald-600 px-3.5 py-5 text-lg font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                onClick={(e) => {
                  handleCreateUser(e);
                }}
              >
                Create Account
              </button>
            </form>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
