import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import CreateAccount from './pages/CreateAccount';
import Login from './pages/Login';
import NavBar from './components/NavBar';
import Feed from './pages/Feed';
import Upload from './components/Upload';
import Memories from './components/Memories';
import ChatBlock from './Chat/ChatBlock';
import Calendar from './pages/Calendar';
import CreateMemories from './CreateMemories';
import Profile from './pages/Profile';
import CompleteProfile from './pages/CompleteProfile';
import { UserAuth } from './context/AuthContext';
import CreateWrittenMemory from './components/CreateWrittenMemory';
import ScrollToTop from './components/ScrollToTop';
import EditProfile from './components/EditProfile';
import Pictures from './components/Pictures';

function App() {
  const {isLoggedIn} = UserAuth();

  return (
   <div>
    <NavBar />
    <ScrollToTop />
    {isLoggedIn ? (
      <>
       <Routes>
       <Route path="/create-account" element={<CreateAccount />} />
       <Route path="/login" element={<Login />} />
       <Route path="/news-feed" element={<Feed />} />
       <Route path="/upload" element={<Upload />} />
       <Route path="/memories" element={<Memories />} />
       <Route path="/create-memories" element={<CreateMemories />} />
       <Route path="/chat/:chatID" element={<ChatBlock />} />
       <Route path="/calendar" element={<Calendar />} />
       <Route path="/welcome" element={<CompleteProfile />} />
       <Route path="/edit-profile" element={<EditProfile />} />
       <Route path="/profile/:id" element={<Profile />} />
       <Route path="/written-memories" element={<CreateWrittenMemory />} />
       <Route path="/pictures-memories" element={<Pictures />} />
       <Route path="/*" element={<Feed />} />
     </Routes>
     </>
    ) : (
      <Routes>
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
      </Routes>
    )}
    
   
   </div>
  );
}

export default App;
