import { Fragment, useState } from 'react'
import {
  FaceFrownIcon,
  FaceSmileIcon,
  FireIcon,
  HandThumbUpIcon,
  HeartIcon,
  PaperClipIcon,
  XMarkIcon,
  XCircleIcon
} from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import { UserAuth } from '../context/AuthContext'
import UploadPhotos from './UploadPhotos'

const moods = [
  { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500', id: 0 },
  { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400', id: 1 },
  { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400', id: 2 },
  { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400', id: 3 },
  { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500', id: 4 },
  { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent', id: 5 },
]

export default function PostStatus() {
  const [selected, setSelected] = useState(moods[5]);
  const [post, setPost] = useState('');

  const { profile, uploadPost, profilePicUrl, files } = UserAuth();


  const postData = {
    id: profile?.id,
    post: post,
    mood: selected.id,
    profileUrl: profile?.profilePic || '',
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    postCount: profile?.postCount

  }

  const handlePost = (e) => {
    e.preventDefault()
    uploadPost(postData);
    setPost('')
  }

  return (
    <div className="flex items-start space-x-4">
    
      <div className="min-w-0 flex-1">
        <form action="#" className="relative">
          <div className="overflow-hidden bg-white rounded-lg shadow-sm ring-1  ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={3}
              name="comment"
              id="comment"
              className="block w-full border-0 bg-white text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:py-1.5 sm:text-sm sm:leading-6"
              value={post}
              placeholder="Add your comment..."
              defaultValue={''}
              onChange={(e) => { setPost(e.target.value) }}
            />

            {files && (
              <div className="grid md:grid-cols-4 grid-cols-2 gap-1 mb-4">
                {files.map((file, index) => (
                  <div key={index}>
                    <img
                      className="w-full h-32 object-cover"
                      key={file.uuid}
                      src={`https://ucarecdn.com/${file.uuid}/${file.cdnUrlModifiers || ""
                        }`}

                      alt="Preview"
                    />
                  </div>
                ))}

              </div>
            )}


            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">

              <div className="flex items-center">
                <UploadPhotos />
              </div>

            </div>
            <div className="flex-shrink-0">
              <button
                type="submit"
                className="inline-flex items-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                onClick={(e) => { handlePost(e) }}
              >
                Post
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
