import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { PencilIcon, PhotoIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusCircleIcon, PlusIcon, Squares2X2Icon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { UserAuth } from '../context/AuthContext'
import MemoryModal from './MemoryModal'
import StoryModal from './StoryModal'
import ViewMemory from './ViewMemory'


export default function Memories() {
  const { getAllMemories, getAllWrittenMemories, memories, writtenmemories } = UserAuth();
  const [memoryUrls, setMemoryUrls] = useState([]);
  const [newWrittenMemories, setWrittenMemories] = useState([]);
  const [year, setYear] = useState('');
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [story, setStory] = useState('');
  const [storyOpen, setStoryOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [memoryOpen, setMemoryOpen] = useState(false);
  const [storyID, setStoryID] = useState('');

  const handleClose = () => {
    setOpen(false);
    setStoryOpen(false);
    setMemoryOpen(false);
  }

  useEffect(() => {
    getAllMemories();
    getAllWrittenMemories()
  }, [])

  useEffect(() => {
    let filteredMemories = memories;
    if (year) {
      filteredMemories = filteredMemories.filter((memory) => memory.year === year);
    }
    if (name) {
      filteredMemories = filteredMemories.filter((memory) => memory.people.includes(name));
    }
    if (location) {
      filteredMemories = filteredMemories.filter((memory) => memory.location === location);
    }
    if (story) {
      filteredMemories = filteredMemories.filter((memory) => memory.story === story);
    }
    const groupedPhotos = filteredMemories.reduce((accumulator, memory) => {
      if (memory.photos) {
        memory.photos.forEach((photo) => {
          const key = `${memory.year}:${memory.location}:${memory.people.join(',')}:${memory.story}`;
          if (accumulator[key]) {
            accumulator[key].push(photo.cdnUrl);
          } else {
            accumulator[key] = [photo.cdnUrl];
          }
        });
      }
      return accumulator;
    }, {});

    const photoGroups = Object.entries(groupedPhotos).map(([key, cdnUrls]) => {
      const [year, location, names, story] = key.split(':');
      return {
        year,
        location,
        names: names.split(','),
        story,
        cdnUrls,
      };

    });

    setMemoryUrls(photoGroups);
  }, [memories, year, name, location]);


  useEffect(() => {
    let filteredMemories = writtenmemories;
    if (year) {
      filteredMemories = filteredMemories.filter((memory) => memory.year === year);
    }
    if (name) {
      filteredMemories = filteredMemories.filter((memory) => memory.people === name);
    }
    if (location) {
      filteredMemories = filteredMemories.filter((memory) => memory.location === location);
    }
    if (story) {
      filteredMemories = filteredMemories.filter((memory) => memory.story === story);
    }

    const photoGroups = filteredMemories.map((memory) => {
      const key = `${memory.year}:${memory.location}:${memory.people}:${memory.story}`;
      return {
        year: memory.year,
        location: memory.location,
        names: memory.people,
        story: memory.story,
        title: memory.title,
      };
    });

    setWrittenMemories(photoGroups);
  }, [writtenmemories, year, name, location, story]);

  const handleOpenStoryModal = (memory) => {
    setStoryID(memory);
    setStoryOpen(true);
  }

  const handleOpenMemoryModal = () => {
    setMemoryOpen(true);
  }
    

  return (
    <div className="bg-white h-screen">
     
      <div>
        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 text-center mt-4 sm:hidden">Memories</h1>
          <div className="flex items-baseline justify-between border-b border-gray-200 mt-4 pb-6">
            <h1 className="hidden sm:block text-4xl font-bold tracking-tight text-gray-900">Memories</h1>

            <div className="sm:flex sm:items-center ">

              <div className='mr-3'>
                <select
                  id="year"
                  name="year"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChange={(event) => setYear(event.target.value)}
                >
                  <option value="">Every Year</option>
                  {[...new Set([...memories, ...writtenmemories].flatMap(memory => memory.year))].map((year, index) => (
                    <option key={index} value={year}>{year}</option>
                  ))}
                </select>
              </div>
              <div className='mr-3'>
                <select
                  id="people"
                  name="people"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChange={(event) => setName(event.target.value)}
                >
                  <option value="">Everyone</option>
                  {[...new Set(memories.flatMap(memory => memory.people))].map((name, index) => (
                    <option key={index} value={name}>{name}</option>
                  ))}
                </select>
              </div>
              <div>
                <select
                  id="location"
                  name="location"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue=""
                  onChange={(event) => setLocation(event.target.value)}
                >
                  <option value="">All Locations</option>
                  {[...new Set(memories.map(memory => memory.location))].map((location, index) => (
                    <option key={index} value={location}>{location}</option>
                  ))}
                </select>
              </div>




            </div>
          </div>
          <div className='sm:flex sm:inline-center'>
            <Link to="/create-memories" className="sm:ml-auto sm:flex-shrink-0 mt-2 p-2 bg-emerald-700 hover:bg-emerald-900 rounded-md text-white items-center sm:flex hidden ">
              <PhotoIcon className='h-6 mr-2 flex' /> Add Photo Memories
            </Link>
            <Link to="/written-memories" className="mt-2 p-2 bg-emerald-700 hover:bg-emerald-900 rounded-md text-white  items-center sm:flex ml-4 hidden">
              <PencilIcon className='h-6 mr-2' /> Add Written Memories
            </Link>
          </div>

          <section aria-labelledby="products-heading" className="pt-6 pb-24">
            <div>
              <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                {memoryUrls?.map((file, index) => (
                  <>
                    {file?.cdnUrls?.map((url, i) => (
                      <li key={`${file.source}-${i}`} className="relative">
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                          <img src={url} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                          <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => handleOpenMemoryModal()}>
                            <span className="sr-only">View details for {file.title}</span>
                          </button>
                          <ViewMemory isOpened={memoryOpen} onClose={handleClose} memory={memoryUrls} index={i}/>
                        </div>
                        {/* <div className='flex items-baseline'>
                          {file.names.map((name, index) => (
                            <React.Fragment key={name}>
                              <p className="inline-block pointer-events-none mt-2 truncate text-sm font-medium text-gray-900">{name}</p>
                              {index < file.names.length - 1 && <span className="text-gray-500 mr-1">, </span>}
                            </React.Fragment>
                          ))}
                        </div> */}
                        <p className="pointer-events-none block text-sm font-medium text-gray-9text-gray-900">{file.location}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.year}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500 truncate">{file?.story}</p>
                      </li>
                    ))}
                  </>
                ))}
                {newWrittenMemories?.map((memory, index) => (
                  <li key={index} className="relative">
                    <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gradient-to-r from-lime-100 to-emerald-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                      <button type="button" className="absolute inset-0 focus:outline-none" onClick={() => handleOpenStoryModal(memory)}>
                        <time dateTime={memory.year} className="block text-sm text-gray-600">
                          {memory.year}
                        </time>
                        <h2 className="text-lg font-semibold text-gray-900 group-hover:text-gray-600 px-4">
                          <span className="absolute inset-0" />
                          {memory.title}
                        </h2>
                        <span className="sr-only">View details for {memory.year}</span>
                        <p className="mt-2 text-sm leading-6 text-gray-600">by:</p>
                        <p className="text-sm font-bold leading-6 text-gray-600">{memory.names}</p>
                      </button>
                    </div>
                    <p className="pointer-events-none block text-sm font-medium text-gray-900">{memory.location}</p>
                    <p className="pointer-events-none block text-sm font-medium text-gray-500">{memory.year}</p>
                  </li>
                ))}
                <StoryModal isOpened={storyOpen} onClose={handleClose} storyID={storyID}/>
              </ul>
            </div>
          </section>
          <div className='relative '>
            <button className='fixed bottom-4 right-4 rounded-full bg-white sm:hidden'><PlusCircleIcon className='h-14 rounded-full shadow text-blue-500 ' onClick={() => { setOpen(true) }} /></button>
          </div>

          <MemoryModal isOpened={open} onClose={handleClose} />
        </main>
      </div>
    </div >
  )
}
