import logo from "../assets/logo.svg";
import { UserAuth } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Login() {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const { signIn, googleSignIn } = UserAuth();

  const navigate = useNavigate();

  const handleGoogleLogin = () => {
    googleSignIn()
  }

  const handleLogin = (e) => {
    e.preventDefault();
    if (email !== null && password !== null) {
      signIn(email, password);
    } else {
      alert('Please enter an email and password')
    }

  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 shadow px-6 py-10">
            <h2 className=" text-xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>
            <form className="space-y-4">
              <div>
                <div className="mt-4">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full h-12 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                  />
                </div>
              </div>

              <div>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full h-12 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-emerald-500 focus:outline-none focus:ring-emerald-500 sm:text-sm"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="text-sm text-center">
                <a
                  href="/"
                  className="font-bold text-emerald-600 hover:text-emerald-500"
                >
                  Forgot your password?
                </a>
              </div>


              <div>
                <button
                  className="flex w-full justify-center rounded-md border border-transparent bg-emerald-600 p-3 text-lg font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                  onClick={(e) => { handleLogin(e) }}
                >
                  Sign in
                </button>
              </div>
              <div className="relative">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span className="bg-white px-2 text-sm text-gray-500">or</span>
                </div>
              </div>
              <div>
                <Link
                  to="/create-account"
                  className="flex w-full justify-center rounded-md border border-transparent bg-white p-3 text-lg font-medium text-emerald-600 ring ring-emerald-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-emerald-900 focus:ring-offset-2"
                >
                  Create new account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
