import { PencilSquareIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import tempPhoto from "../assets/profilepic.png"

export default function ProfileCard() {
  const { updateStatus, profile, allUsers } = UserAuth();

  const [statuseChange, setStatusChange] = useState(false);
  const [status, setStatus] = useState('')

  const navigate = useNavigate()

  const statusData = {
    id: profile.id,
    text: status,
    profileUrl: profile.profilePic,
    firstName: profile.firstName,
    lastName: profile.lastName
  }


  const handleStatusUpdate = (e) => {
    e.preventDefault();
    updateStatus(statusData);
    setStatusChange(false);
  }

  const handleUpload = () => {
    navigate('/upload')
  }



  return (
    <div className="lg:block hidden">

      <div className="mx-auto text-center shadow-lg pb-5 bg-gray-50 rounded-md">
        <div
          className="relative bg-center bg-no-repeat bg-cover rounded-t-lg"
          style={{
            backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/e/e7/Everest_North_Face_toward_Base_Camp_Tibet_Luca_Galuzzi_2006.jpg)`,
            height: "100px"
          }}

        >
          <div className="flex flex-wrap justify-center ">
            <button onClick={handleUpload}>

            <img
              className="object-cover object-center mx-auto mt-10 mb-4 w-24 h-24 object-center rounded-lg border-solid border-4 border-white"
              src={profile?.profilePic || tempPhoto}
              alt=""
            />
            </button>

          </div>
        </div>
        <p className="text-xl font-bold mt-10">
        </p>
        <p className="text-md font-bold tracking-tight">{profile?.firstName} {profile?.lastName}</p>
        <p className="text-md">{profile?.proffesion}</p>
        <p className="text-sm">
          {profile?.city} {profile?.state}
        </p>
        <div className="mt-4 p-2">
          <div className="text-md bg-white rounded-md border border-1">
            {statuseChange ? (
              <>
                <textarea
                  rows={4}
                  name="comment"
                  id="comment"
                  className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:py-1.5 sm:text-sm sm:leading-6"
                  defaultValue={''}
                  placeholder="update your status"
                  onChange={(e) => { setStatus(e.target.value) }}
                />
                <button className="mt-2 bg-emerald-600 hover:bg-emerald-600 w-full p-4 rounded-md text-white" onClick={(e) => { handleStatusUpdate(e) }}>Update</button>
              </>
            ) : (

              <>

                {profile?.status ? (<div className="text-left p-4">
                  <div className="text-xs font-medium tracking-tight">Your Current Status:</div>
                  <div className="flex justify-between mt-4">
                    <div>{profile?.status}</div>
                    <button className="flex items-end justify-end" onClick={() => { setStatusChange(true) }}>
                      <PencilSquareIcon className="h-4 mb-1 mr-1 text-emerald-600 hover:text-emerald-800" />
                    </button>
                  </div>
                </div>


                ) : <div className="text-left p-4">
                  This is your status. Feel free to <span className="text-emerald-500"><button onClick={() => { setStatusChange(true) }}>update it</button></span> at any time.
                </div>}



              </>
            )}



          </div>

        </div>

        <div>
          <div className="grid grid-cols-3 mt-4 ">
            <div className="text-md">
              <div className="font-bold">{profile?.postCount || 0}</div> <div className="text-xs">Posts</div>
            </div>
            <div>
              <div className="font-bold">{allUsers?.length}</div> <div className="text-xs">Family Members</div>
            </div>
            <div>
              <div className="font-bold">{profile?.memories || 0}</div> <div className="text-xs">Memories</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
