import { useState } from 'react';

export default function Comment({ postID, profile, commentOnPost }) {
  const [comment, setComment] = useState('');

  const handleComment = (e) => {
    e.preventDefault();
    commentOnPost(postID, comment, profile);
    setComment('');
  };

  return (
    <div>
      <div className="relative mt-2 rounded-md shadow-sm flex items-center">
        <textarea
          rows={1}
          type="textarea"
          name="account-number"
          id="account-number"
          className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
          placeholder="Add a comment..."
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />

        <button
          className="flex bg-emerald-600 hover:bg-emerald-800 rounded-md px-3 py-1 ml-2 text-white"
          onClick={handleComment}
        >
          Post
        </button>
      </div>
    </div>
  );
}
