import React, { useEffect, useState } from 'react';
import { UserAuth } from './context/AuthContext';
import UploadMemories from './components/UploadMemories';
import { useNavigate } from 'react-router-dom';

export default function CreateMemories() {

    const { uploadMemories, memories, setMemories } = UserAuth()

    const [location, setLocation] = useState('');
    const [people, setPeople] = useState([]);
    const [peopleList, setPeopleList] = useState([]);
    const [year, setYear] = useState('');
    const [story, setStory] = useState('');
    const [selectedMemories, setSelectedMemories] = useState([]);

    const navigate = useNavigate();


    useEffect(() => {
        if (memories[0]?.location === undefined) {
            return;
        } else {
            setMemories([]);
        }
    }, [memories]);


    console.log(`memories: ${memories}`)


    const handleLocationChange = (e) => {
        setLocation(e.target.value);
    };

    const handlePeopleChange = (e) => {
        const peopleString = e.target.value;
        const peopleArray = peopleString.split(',').map((name) => name.trim());
        setPeople(peopleArray);
        setPeopleList(peopleArray);
    };

    const handleBackspace = (e) => {
        if (e.keyCode === 8 && people.length > 0 && people[people.length - 1] === '') {
            setPeople((prevPeople) => prevPeople.slice(0, -1));
            setPeopleList((prevPeopleList) => prevPeopleList.slice(0, -1));
        }
    };


    const handleYearChange = (e) => {
        setYear(e.target.value);
    };

    const handleMemoryClick = (memoryId) => {
        setSelectedMemories((prevSelectedMemories) => {
            if (prevSelectedMemories.includes(memoryId)) {
                // If the memory is already selected, remove it from the array
                return prevSelectedMemories.filter((id) => id !== memoryId);
            } else {
                // If the memory is not selected, add it to the array
                return [...prevSelectedMemories, memoryId];
            }
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
      
        const memoriesData = {
          photos: selectedMemories,
          year: year ? year : "Unknown",
          location: location ? location : "Unknown",
          people: people.length > 0 ? people : ["Unknown"],
          story,
        };
      
        console.log(memoriesData);
      
        // Upload the selected memories
        uploadMemories(memoriesData);
      
        // Filter out the selected memories from the memories array
        setMemories((prevMemories) =>
          prevMemories.filter((memory) => !selectedMemories.includes(memory))
        );
      
        // Reset the form
        setLocation("");
        setPeople([]);
        setPeopleList([]);
        setYear("");
        setSelectedMemories([]);
        if (memories.length === 0) {
          navigate("/memories");
        }
      };
      

    return (
        <div className="max-w-full px-12 mx-auto grid md:grid-cols-12 grid-cols-1 gap-4 mt-4 h-screen">

            <div className='md:col-span-12'>
                <div>
                    <h2 class="text-xl font-bold mb-2">Tagging and Saving Photos</h2>
                    <p class="mb-2">1. Select the photos/videos you want to tag by using the uploader tool.</p>
                    <p class="mb-2">2. Add tags to your photos to categorize and find them later. Tags are used help us categorize and find our photos later. For example, you might tag a photo of your nick names and names like "Tex," "Bret," and "Bret Johnson"</p>
                    <p class="mb-2">3. Click or tap the "Save and Tag Memories" button to store your tagged photos in a safe and organized place.</p>
                    <p class="mb-2">4. Keep tagging more photos by selecting and tagging them. The untagged photos will still be in the list until you have saved them all.</p>
                    <p class="mb-2">5. Save all your photos, even if you don't want to tag them, to get rid of them from the list.</p>
                </div>
            </div>
            <div className="md:col-span-3 bg-gray-200 p-3">
                <div>
                    <div className="text-xl tracking-tight font-bold">
                        First: <span className='font-normal'>Upload Your Memories</span>
                    </div>
                    <div className='mt-4'>
                        <UploadMemories />
                    </div>
                </div>
            </div>

            <div className="md:col-span-6 md:row-span-7 bg-gray-200 p-3">

                <h1 className='text-xl tracking-tight font-bold mb-4'>Second: <span className='font-normal'>Select the files you want to tag</span></h1>


                <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-4 lg:grid-cols-4 xl:gap-x-4">
                    {memories?.map((file, index) => (
                        <li key={index} className="relative">
                            <div
                                className={`group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 ${selectedMemories.includes(file)
                                    ? 'ring-2 ring-indigo-500 ring-offset-2 ring-offset-gray-100'
                                    : ''
                                    }`}
                                onClick={() => handleMemoryClick(file)}
                            >
                                <img
                                    src={file?.cdnUrl}
                                    alt=""
                                    className="pointer-events-none object-cover group-hover:opacity-75"
                                />

                            </div>
                        </li>
                    ))}
                </ul>

            </div>
            <div className='md:col-span-3 bg-gray-200 p-3 relative'>
                <div className="text-xl tracking-tight font-bold">
                    Third: <span className='font-normal'>Tag your Memories</span>
                </div>
                <p className='text-sm'>If you dont know the year, people, or location <span className='font-bold'>NO WORRIES!</span> You can leave anything blank</p>
                <div className="mt-4">
                    <label className="block text-gray-700 font-bold mb-2">Location</label>
                    <input
                        type="text"
                        value={location}
                        onChange={handleLocationChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 font-bold">People </label>
                    <div className='text-xs font-normal mb-4'>Seperate each person by a comma. People can be tagged more than once if you want to use nick names and real names</div>
                    <input
                        type="text"
                        value={people.join(', ')}
                        onChange={handlePeopleChange}
                        onKeyDown={handleBackspace}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {peopleList.length > 0 && (
                        <ul className="list-disc pl-6 mt-2">
                            {peopleList.map((person, index) => (
                                <li key={index}>{person}</li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 font-bold mb-2">Year</label>
                    <input
                        type="text"
                        value={year}
                        onChange={handleYearChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
                <div className="mt-4">
                    <label className="block text-gray-700 font-bold">Written Story or Description (optional)</label>
                    <p className='text-sm mb-2'>If you would like to add a story behind the memories you are welcome to.</p>
                    <textarea
                        rows={4}
                        name="comment"
                        id="comment"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={''}
                        onChange={(e) => setStory(e.target.value)} 
                    />
                </div>
                <div className="mt-8">
                    <button
                        type="submit"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
                        onClick={handleSubmit}
                    >
                        Save and Tag Memories
                    </button>
                </div>
            </div>

        </div>

    );
};

