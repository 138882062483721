import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import UploadProfile from '../components/UploadProfilePic';
import { UserAuth } from '../context/AuthContext';

export default function CompleteProfile() {
    const [saved, setSaved] = useState(false);
    const { profile, finishProfile } = UserAuth();

    const [firstName, setFirstName] = useState(profile?.firstName);
    const [lastName, setLastName] = useState(profile?.lastName);
    const [email, setEmail] = useState(profile?.email);
    const [phone, setPhone] = useState('');
    const [about, setAbout] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [proffesion, setProffesion] = useState('');
    const [hobby, setHobby] = useState('');
    const [college, setCollege] = useState('');
    const [mission, setMission] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('United States');

    const navigate = useNavigate()


    const handleSave = () => {
        setSaved(true);
    }

    const userData ={ 
        firstName: firstName,
        lastName: lastName,
        contactEmail: email,
        phone: phone,
        about: about,
        address: address,
        city: city,
        state: state,
        zip: zip,
        proffesion: proffesion,
        hobby: hobby,
        college: college,
        mission: mission,
        birthday: birthday,
        country: country
    }

    const handleSubmit = () => {
        finishProfile(userData);
        navigate('/news-feed')
    }


    return (
        <div className='bg-gray-100 h-screen'>
            <div className='max-w-4xl mx-auto'>

                {saved ? (
                    <>
                        <div className='text-transparent'>Personal Information</div>
                        <div className="border-b border-gray-900/10 pb-12 sm:mt-12 bg-white p-6 rounded-lg shadow">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                This information will be displayed publicly so be careful what you share.
                            </p>

                            <div className="sm:mt-10 sm:grid sm:gap-x-6 sm:gap-y-8 sm:grid-cols-6">

                                <div className="sm:col-span-3">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 mt-2">
                                        Profession, Major, or Specialty
                                    </label>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="proffesion"
                                            id="proffesion"
                                            className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setProffesion(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3 mt-2">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                        What is your favorite Hobby?
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="hobby"
                                            id="hobby"
                                            className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setHobby(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3 mt-2">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                        Did you go to College? If so where?
                                    </label>
                                    <div className="">
                                        <input
                                            type="text"
                                            name="college"
                                            id="college"
                                            autoComplete="college"
                                            className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setCollege(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3 mt-2">
                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                        Did you serve your mission? If so where?
                                    </label>
                                    <div >
                                        <input
                                            type="text"
                                            name="mission"
                                            id="mission"
                                            autoComplete="mission"
                                            className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setMission(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-full mt-2">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        About
                                    </label>
                                    <div>
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows={3}
                                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                                            defaultValue={''}
                                            onChange={(e) => setAbout(e.target.value)}
                                        />
                                    </div>
                                    <p className=" text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p>
                                </div>

                                <div className="sm:col-span-2 mt-2">
                                    <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                                        Profile Photo
                                    </label>
                                    <UploadProfile />
                                </div>
                                <div className="sm:col-span-3 mt-2">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Birthday
                                    </label>
                                    <div>
                                        <input
                                            type="date"
                                            name="birthday"
                                            id="birthday"
                                            autoComplete="birthday"
                                            className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            onChange={(e) => setBirthday(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className='text-right mt-4'>
                                <button className='bg-white px-4 py-2 rounded-md text-emerald-500 border border-1 border-emerald-500 mr-2 hover:bg-gray-100' onClick={() => { setSaved(false) }}>Back</button>
                                <button className='bg-emerald-500 px-4 py-2 rounded-md text-white hover:bg-emerald-700' onClick={handleSubmit}>Save</button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='text-transparent'>Personal Information</div>
                        <div className="rounded-lg bg-white shadow sm:mt-12">
                            <div className="px-4 py-5 sm:p-6 relative">
                                <div className="border-b border-gray-900/10 pb-12">
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>

                                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:gap-y-8 sm:grid-cols-6">
                                        <div className="sm:col-span-3">
                                            <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                First name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    autoComplete="given-name"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                                Last name
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="last-name"
                                                    id="last-name"
                                                    autoComplete="family-name"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Email address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <p className='text-xs ml-2'>This does not change your login email, this is the email you want others to see, and if they need to contact you offline</p>
                                        </div>
                                        <div className="sm:col-span-3">
                                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                Phone
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="tel"
                                                    name="phone"
                                                    type="tel"
                                                    autoComplete="tel"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-3">
                                            <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                                Country
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="country"
                                                    name="country"
                                                    type="text"
                                                    autoComplete="country"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    placeholder="United States"
                                                    onChange={(e) => setCountry(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-span-full">
                                            <label htmlFor="street-address" className="block text-sm font-medium leading-6 text-gray-900">
                                                Street address
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="street-address"
                                                    id="street-address"
                                                    autoComplete="street-address"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2 sm:col-start-1">
                                            <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                                                City
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    autoComplete="address-level2"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="region" className="block text-sm font-medium leading-6 text-gray-900">
                                                State / Province
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="region"
                                                    id="region"
                                                    autoComplete="address-level1"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setState(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="postal-code" className="block text-sm font-medium leading-6 text-gray-900">
                                                ZIP / Postal code
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    name="postal-code"
                                                    id="postal-code"
                                                    autoComplete="postal-code"
                                                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    onChange={(e) => setZip(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-right mt-4'>
                                    <button className='bg-emerald-500 px-4 py-2 rounded-md text-white' onClick={handleSave}>Save</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}


            </div>

        </div>
    )
}
