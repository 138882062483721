import React, { useEffect, useRef } from "react";
import { UserAuth } from "../context/AuthContext";
import { useMessages } from "./useMessages";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MessageList({ roomID }) {
  const { profile, setMessageLimit, messageLimit } = UserAuth();
  const  messages  = useMessages(roomID, messageLimit);
  const mySectionRef = useRef(null);

  useEffect(() => { 
    mySectionRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleLoadMore = () => {
    setMessageLimit((prevLimit) => prevLimit + 10);
  };

 

  return (
    <div className="message-list-container">
      <button
        className="load-more-button"
        onClick={handleLoadMore}
      >
        Load more
      </button>
      <ul className="message-list h-full">
        {messages?.map((x) => ( // Removed reverse() here
          <Message key={x.id} message={x} isOwnMessage={x.user_id === profile?.id} />
        ))}
        <div ref={mySectionRef}></div>
      </ul>
    </div>
  );
}




function Message({ message, isOwnMessage }) {
  const { firstName, lastName, text, profileUrl } = message;

  const colors = [
    "text-blue-500",
    "text-red-500",
    "text-yellow-500",
    "text-green-500",
    "text-indigo-500",
    "text-purple-500",
    "text-pink-500",
    "text-teal-500",
    "text-gray-500",
    "text-orange-500",
  ];

  const getColorClass = (name) => {
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const nameClass = getColorClass(firstName);


  return (
    <div>
    <div className="flex flex-col space-y-4 p-3  ">
      <div className={classNames(isOwnMessage ? "flex items-end justify-end" : "flex items-end")}>
      <img src={profileUrl} className={classNames("object-cover object-center h-8 w-8 rounded-full flex", isOwnMessage ? "order-2" : "order-1")} alt="" />
        <div
          className={classNames(
            isOwnMessage
              ? "flex flex-col space-y-2 text-sm max-w-xs mx-2 order-1 items-end "
              : "flex flex-col space-y-2 text-sm max-w-xs mx-2 order-2 items-start"
          )}
        >
          
          <div
            className={classNames(
              isOwnMessage
                ? "px-4 py-2 rounded-lg inline-block rounded-br-none bg-emerald-600 text-white shadow-md "
                : "px-4 py-2 rounded-lg inline-block rounded-bl-none bg-gray-200 text-gray-600",
              "w-fit rounded-md shadow-md"
            )}
          >
            <h4 className={classNames(isOwnMessage ? "text-right" : "text-left", "text-emerald-500 font-bold")}
>
              {isOwnMessage ? <></> : <span className={nameClass}>{firstName} {lastName}</span>}
            </h4>
            <div>{text}</div>
          </div>
          
          
        </div>
      </div>

    </div>
    </div>
  );
}

export { MessageList };
