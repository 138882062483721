import React, { useEffect, useState } from 'react'
import { UserAuth } from '../context/AuthContext'
import {
    HandThumbUpIcon,
    FaceFrownIcon,
    FaceSmileIcon,
    FireIcon,
    HeartIcon,
    XMarkIcon,
} from '@heroicons/react/20/solid'
import PostDropDown from './PostDropDown'
import Comment from './Comment'
import profilePic from '../assets/profilepic.png'
import LikesModal from './LikesModal'
import { Link } from 'react-router-dom'

const moods = [
    { name: 'Excited', value: 'excited', icon: FireIcon, iconColor: 'text-white', bgColor: 'bg-red-500', id: 0 },
    { name: 'Loved', value: 'loved', icon: HeartIcon, iconColor: 'text-white', bgColor: 'bg-pink-400', id: 1 },
    { name: 'Happy', value: 'happy', icon: FaceSmileIcon, iconColor: 'text-white', bgColor: 'bg-green-400', id: 2 },
    { name: 'Sad', value: 'sad', icon: FaceFrownIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400', id: 3 },
    { name: 'Thumbsy', value: 'thumbsy', icon: HandThumbUpIcon, iconColor: 'text-white', bgColor: 'bg-blue-500', id: 4 },
    { name: 'I feel nothing', value: null, icon: XMarkIcon, iconColor: 'text-gray-400', bgColor: 'bg-transparent', id: 5 },
]

export default function Posts() {
    const { posts, profile, postLiked, profilePicUrl, postUnliked, commentOnPost, setLikedPosts, likedPosts, postLimit, setPostLimit, } = UserAuth()
    const [likesOpen, setLikesOpen] = useState(false)
    const [comment, setComment] = useState('');
    const [peopleWhoLiked, setPeopleWhoLiked] = useState([]);
    const [selectedPostId, setSelectedPostId] = useState(null);


    const getTimeElapsed = (timestamp) => {
        const now = Date.now();
        const diff = now - timestamp?.toMillis();
        const seconds = Math.floor(diff / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return `${days} day${days > 1 ? "s" : ""} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? "s" : ""} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
        }
    };

    const handleLike = (docRef, currentLikes, id, e) => {
        e.preventDefault()
        postLiked(docRef, currentLikes, profile, profilePicUrl);
        setLikedPosts(prevState => ({ ...prevState, [id]: true }));
    }

    const handleUnlike = (docRef, currentLikes, id, e) => {
        e.preventDefault();
        postUnliked(docRef, currentLikes, profile, profilePicUrl)
        setLikedPosts(prevState => ({ ...prevState, [id]: false }));
    };

    const handleViewMore = () => {
        setPostLimit((prevLimit) => prevLimit + 10);
    };

    const handleOpenLikes = (people) => {
        setPeopleWhoLiked(people);
        setLikesOpen(true);
    };

    const handleClose = () => {
        setLikesOpen(false);
    }
    



    return (
        <div className='h-screen w-full'>
            {posts?.map((post) => (
                <>
                    <div key={post.user_id} className="w-full bg-gray-100 p-4 mt-2 drop-shadow-lg rounded-md border border-1">

                        {profile?.id === post.user_id && (
                            <div className='absolute right-6 top-4'><PostDropDown /></div>
                        )}


                        <div className="flex space-x-3">

                            <div className="flex-shrink-0">
                                <img
                                    className="h-10 w-10 rounded-full object-center object-cover"
                                    src={post?.profileUrl || profilePic}
                                    alt=""
                                />
                            </div>
                            <div className="min-w-0 flex-1">
                                <p className="text-sm font-semibold text-gray-900">
                                    <Link to={`/profile/${post.user_id}`} className="hover:underline">
                                        {post.firstName} {post.lastName}
                                    </Link>
                                </p>
                                <p className="text-sm text-gray-500">
                                    <div>
                                        {getTimeElapsed(post.timestamp)}
                                    </div>
                                </p>
                            </div>
                        </div>
                        <div className='mt-4 ml-2'>{post?.post}</div>

                        <div className='mb-4 mt-2'>
                            {/* Left Image */}
                            {post.imageUrls?.length === 1 && (
                                <div className="">
                                    <img
                                        className='object-cover object-center rounded-lg w-full h-full'
                                        style={{ aspectRatio: '1 / 1' }}
                                        src={post.imageUrls[0].cdnUrl}
                                        alt={post.imageUrls[0].name}
                                    />
                                </div>
                            )}
                            {/* Right Images */}
                            {post.imageUrls?.length === 2 && (
                                <div className="flex flex-wrap justify-between">
                                    <div className="w-1/2">
                                        <img
                                            className='object-cover object-center rounded-lg w-full p-1'
                                            src={post.imageUrls[0].cdnUrl}
                                            alt={post.imageUrls[0].name}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <img
                                            className='object-cover object-center rounded-lg w-full p-1'
                                            src={post.imageUrls[1].cdnUrl}
                                            alt={post.imageUrls[1].name}
                                            style={{ aspectRatio: '1 / 1' }}
                                        />
                                    </div>
                                </div>

                            )}
                            {post.imageUrls?.length >= 3 && (
                                <div className='grid grid-cols-2 gap-2'>
                                    <div className="row-span-2 col-span-1 h-full">
                                        <img
                                            className='object-cover object-center rounded-lg h-full w-full'
                                            src={post.imageUrls[0].cdnUrl}
                                            alt={post.imageUrls[0].name}
                                        />
                                    </div>
                                    <div className="col-span-1 h-full">
                                        <img
                                            className='object-cover object-center rounded-lg h-40 w-full'
                                            src={post.imageUrls[1].cdnUrl}
                                            alt={post.imageUrls[1].name}
                                        />
                                    </div>
                                    <div className="col-span-1 h-full relative">
                                        <img
                                            className='object-cover object-center rounded-lg h-full w-full '
                                            src={post.imageUrls[2].cdnUrl}
                                            alt={post.imageUrls[2].name}
                                        />
                                        <button className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10 h-full w-full rounded-lg" >
                                            <span className="text-white text-xl font-bold">View All</span>
                                        </button>

                                    </div>
                                </div>

                            )}


                        </div>

                        <span className="isolate inline-flex rounded-md shadow-sm">

                            {likedPosts[post.docRef] ? (
                                <button
                                    type="button"
                                    className="relative inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-emerald-500 focus:z-10 bg-emerald-600 text-white"
                                    onClick={(e) => { handleUnlike(post.docRef, post.likes, post.docRef, e) }}
                                >
                                    <HandThumbUpIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Unlike
                                </button>

                            ) : (
                                <button
                                    type="button"
                                    className="relative inline-flex items-center gap-x-1.5 rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 bg-white text-gray-900 "
                                    onClick={(e) => { handleLike(post.docRef, post.likes, post.docRef, e) }}
                                >
                                    <HandThumbUpIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                    Like
                                </button>

                            )}

                            <button
                                type="button"
                                className="relative inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold  focus:z-10 bg-white hover:bg-gray-100 text-gray-900 "
                                onClick={() => handleOpenLikes(post.peopleWhoLiked)}
                            >
                                {post.likes}
                            </button>
                            
                        </span>
                        <div className='mt-4'>
                            <div>
                                <Comment postID={post.docRef} profile={profile} commentOnPost={commentOnPost} />
                            </div>
                        </div>
                        
                        <div>
                            {post.comments?.map((comment, index) => (
                                <div key={index} className='flex items-start mt-4'>
                                    <div className='flex'>
                                        <img className='flex-shrink-0 w-8 h-8 rounded-lg object-cover object-center' src={comment.profileUrl || profilePic} alt={comment.firstName} />
                                    </div>
                                    <div className='ml-2 w-full'>
                                        <div className='bg-white shadow p-3 rounded-b-md rounded-tr-md'>
                                            <div className="flex items-center justify-between">
                                                <p className='text-sm font-semibold'>{comment.firstName} {comment.lastName}</p>
                                                <p className="text-sm text-gray-500 ml-auto">
                                                    {getTimeElapsed(comment.timestamp)}
                                                </p>
                                            </div>
                                            <p className='text-sm mt-2'>{comment.comment}</p>
                                        </div>
                                        {/* <div className='mt-2 flex items-center'>
                                            <span className='text-sm text-gray-500 mr-2'>Like {comment.likes}</span>
                                            <button className='text-sm text-blue-500'>Reply</button>
                                        </div> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                        
                    </div>

                </>
            ))}
            <div className='text-center mt-4'><button className='text-emerald-600 hover:text-emerald-800' onClick={handleViewMore}>View More...</button></div>
            <LikesModal isOpened={likesOpen} onClose={handleClose} likes={peopleWhoLiked}  />
        </div>
    )
}
