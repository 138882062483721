import { useCallback, useRef, useEffect, useState } from "react";
import * as LR from "@uploadcare/blocks";
import st from "./App.module.css";
import { PACKAGE_VERSION } from "@uploadcare/blocks/env";
import { UserAuth } from "../context/AuthContext";
import { UserCircleIcon } from "@heroicons/react/20/solid";
LR.registerBlocks(LR);

function Upload() {
  const { setProfilePic, profile, files, setFiles } = UserAuth();
  const [upload, setUpload] = useState(false);
  let dataOutputRef = useRef();

  const handleUpload = () => {
    setProfilePic();
    }

  const handleUploaderEvent = useCallback((e) => {
    const { data } = e.detail;    
    setFiles(data);
    setUpload(true);
  }, []);

  useEffect(() => {
    let el = dataOutputRef.current;
    el.addEventListener("lr-data-output", handleUploaderEvent);
    return () => {
      el.removeEventListener("lr-data-output", handleUploaderEvent);
    };
  }, [handleUploaderEvent]);

  return (


    <div className="max-w-sm mx-auto mt-8"  >
      <div className="w-full">
        <div className="mt-2 flex items-center gap-x-3">
          {profile?.profilePic && !upload ? (
            <img
            className="object-cover object-center inline-block h-24 w-24 rounded-full"
            src={profile?.profilePic}
            alt=""
          />
          ) : (
            <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
          )}
          
          
          <lr-file-uploader-regular
        class={st.config}
        css-src={`https://unpkg.com/@uploadcare/blocks@${PACKAGE_VERSION}/web/file-uploader-regular.min.css`}
      >
        <lr-data-output
          ref={dataOutputRef}
          use-event
          hidden
          class={st.config}
          onEvent={handleUploaderEvent}
        ></lr-data-output>
      </lr-file-uploader-regular>

      {upload && (
        <>
         <img
         className="object-cover object-center inline-block h-24 w-24 rounded-full"
         src={files[0]?.cdnUrl}
         alt=""
       />
       

        <button onClick={handleUpload}>Finished</button>
        </>
      )}

        </div>
      </div>

      
    </div>
  );
}

export default Upload;
