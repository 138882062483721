import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function ViewMemory({ isOpened, onClose, memory, index }) {
    const [open, setOpen] = useState(isOpened)
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(index)

    useEffect(() => {
        setOpen(isOpened);
        setCurrentPhotoIndex(index);
    }, [isOpened]);





    const handleClose = () => {
        setOpen(false);
        onClose();
    };

    const goToNextPhoto = () => {
        setCurrentPhotoIndex((currentPhotoIndex + 1) % memory.length)
    }

    const goToPrevPhoto = () => {
        setCurrentPhotoIndex((currentPhotoIndex - 1 + memory.length) % memory.length)
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={handleClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-center object-cover rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 max-w-5xl mx-auto sm:p-6">
                                <div>
                                    {memory?.map((file, index) => (
                                        <>
                                            {file?.cdnUrls?.map((url, i) => (
                                                <div key={`${file.source}-${i}`} className="relative">
                                                    <img src={url} alt={`${currentPhotoIndex + 1}`} />
                                                </div>
                                            ))}
                                        </>
                                    ))}

                                </div>
                                <div className="mt-5 sm:mt-6 flex justify-between">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none"
                                        onClick={goToPrevPhoto}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md bg-gray-200 px-3 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none"
                                        onClick={goToNextPhoto}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}