import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { HandThumbUpIcon, PencilIcon, PhotoIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'

export default function LikesModal({isOpened, onClose, likes}) {
  const [open, setOpen] = useState(isOpened)

  const cancelButtonRef = useRef(null)

  useEffect(() => {
    setOpen(isOpened);
  }, [isOpened]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };


  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-emerald-100">
                    <HandThumbUpIcon className="h-6 w-6 text-emerald-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      People who likeds your post
                    </Dialog.Title>
                    <div className="mt-6">
                        {likes?.map((like) => (
                            <div className="flex items-center space-x-3 mb-2">
                                <Link to={`/profile/${like.id}`} className="hover:underline flex items-center">
                                <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full object-center object-cover mr-3" src={like.picture} alt="" />
                                </div>
                                <div className="min-w-0 flex-1">
                                    <p className="text-sm font-medium text-gray-900 text-left">
                                        {like.name}
                                    </p>
                                </div>
                                </Link>
                            </div>
                        ))}
                    <p className="block text-sm text-left text-gray-900"></p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 ">
                  <button
                    className="inline-flex w-full justify-center rounded-md bg-emerald-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 flex items-center"
                    onClick={handleClose}
                  > Close Likes
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
