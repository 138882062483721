import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

export default function CreateWrittenMemory() {
  const { profile, uploadWrittenMemories } = UserAuth();
  const [content, setContent] = useState('');
  const [people, setPeople] = useState(profile?.firstName + ' ' + profile?.lastName);
  const [location, setLocation] = useState('Unknown');
  const [year, setYear] = useState('Unknown');
  const [title, setTitle] = useState('No Title');

  const navigate = useNavigate();


  function handleChange(value) {
    setContent(value);
  }

  const memoryData = {
    story: content,
    people: people,
    location: location,
    year: year,
    title: title,
  }


  const handleSubmitMemory = () => {
    try {
      uploadWrittenMemories(memoryData);
      navigate('/memories');
    } catch (error) {
      console.log(error)
    }

  }



  return (
    <div className='max-w-7xl mx-auto p-4 h-screen'>
      <h2 className="text-2xl font-bold text-center sm:truncate sm:tracking-tight sm:hidden">
        Share a Written Memory
      </h2>
      <h2 className="text-2xl font-bold leading-7 text-emerald-600 sm:truncate sm:text-3xl text-center sm:tracking-tight">
        Share a Memory
      </h2>

      <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600 mt-4">
        <label htmlFor="name" className="block text-xs font-medium text-gray-900">
          Title
        </label>
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
          placeholder="Enter a title..."
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <div className='rounded-lg mt-2'>
        <ReactQuill value={content} className="h-96" onChange={handleChange} />
      </div>
      <div className='mt-20 sm:mt-14 sm:grid-cols-3 grid-cols-1 grid gap-2'>
        <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
            Author
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder={profile.firstName + ' ' + profile.lastName}
            onChange={(e) => setPeople(e.target.value)}
          />
        </div>
        <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
            When did this happen?
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="1986"
            onChange={(e) => setYear(e.target.value)}
          />
        </div>
        <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
            Where did this happen?
          </label>
          <input
            type="text"
            name="name"
            id="name"
            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="City, State, Country, or Place"
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>
        <div>
          <button
            type="button"
            className="sm:hidden rounded-md bg-emerald-700 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 w-full"
          >
            Publish
          </button>
        </div>
      </div>
      <div className="md:flex md:items-center md:justify-between bg-transparent py-4 px-3 rounded-lg mt-4 hidden sm:block">
        <div className="min-w-0 flex-1">

        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-emerald-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-800 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            onClick={handleSubmitMemory}
          >
            Publish and Save
          </button>
        </div>
      </div>
    </div>
  )
}
